// extracted by mini-css-extract-plugin
export var container = "notFound-module--container--1xaiZ";
export var box = "notFound-module--box--3miH9";
export var box2 = "notFound-module--box2--2sFBr";
export var box3 = "notFound-module--box3--3d64c";
export var title = "notFound-module--title--1LvvP";
export var text = "notFound-module--text--3odlW";
export var boxText = "notFound-module--boxText--1QLIo";
export var button = "notFound-module--button--2gkP4";
export var innerCircle = "notFound-module--innerCircle--3SQyh";
export var outerCircle = "notFound-module--outerCircle--1tkWX";
export var paragraph = "notFound-module--paragraph--2j6iu";
export var macOS = "notFound-module--macOS--Tjyxv";
export var threerRectangles = "notFound-module--threerRectangles--3IMGG";