import * as styles from "./notFound.module.scss"

import Button from "../../atoms/button/button"
import Circle from "../../atoms/circle/circle"
import { Link } from "gatsby"
import MacOsContainer from "../../atoms/macOsContainer/macOsContainer"
import MainSubTitle from "../../atoms/mainSubTitle/mainSubTitle"
import MainTitle from "../../atoms/mainTitle/mainTitle"
import React from "react"
import Rectangle from "../../atoms/rectangle/rectangle"
import { useWindowSize } from "../../../hooks/useWindowSize"

const NotFound = () => {
  const windowSize = useWindowSize()
  return (
    <div className={styles.container}>
      <div className={styles.box}>
        <Circle
          Circle
          radius={146}
          borderColor={"var(--wdd-yellow)"}
          borderThickness={5}
        >
          <div className={styles.boxText}>
            <div className={styles.title}>
              <MainTitle contentTitle={"404"}></MainTitle>
            </div>
            <div className={styles.text}>
              <MainSubTitle>Oh no! Ti sei perso?</MainSubTitle>
            </div>
          </div>
        </Circle>
        <Rectangle
          offset={{ x: 100, y: -65 }}
          color={"var(--wdd-cyan)"}
          length={160}
          thickness={5}
        ></Rectangle>
        <Rectangle
          offset={{ x: 120, y: -85 }}
          color={"var(--wdd-cyan)"}
          length={120}
          thickness={5}
        ></Rectangle>
        <Rectangle
          offset={{ x: -100, y: 100 }}
          color={"var(--wdd-cyan)"}
          length={70}
          thickness={5}
        ></Rectangle>
        <Rectangle
          offset={{ x: -130, y: 115 }}
          color={"var(--wdd-cyan)"}
          length={70}
          thickness={5}
        ></Rectangle>
      </div>

      <div className={styles.paragraph}>
        <div className={styles.box2}>
          <Circle
            Circle
            radius={162}
            borderColor={"var(--wdd-white)"}
            borderThickness={5}
          >
            <div className={styles.box3}>
              <Circle
                Circle
                radius={146}
                borderColor={"var(--wdd-yellow)"}
                borderThickness={7}
              ></Circle>
            </div>
          </Circle>
          <Rectangle
            offset={{ x: 100, y: -65 }}
            color={"var(--wdd-purple)"}
            length={180}
            thickness={10}
          ></Rectangle>
          <Rectangle
            offset={{ x: 120, y: -85 }}
            color={"var(--wdd-purple)"}
            length={180}
            thickness={10}
          ></Rectangle>
          <Rectangle
            offset={{ x: -120, y: 100 }}
            color={"var(--wdd-purple)"}
            length={100}
            thickness={30}
          ></Rectangle>
        </div>
        <div className={styles.macOS}>
          <MacOsContainer buttonsColor={"purple"} buttonsPosition={"end"}>
            <div className={styles.boxText}>
              <div className={styles.title}>
                <MainTitle contentTitle={"404"}></MainTitle>
              </div>
              <div className={styles.text}>
                <MainSubTitle>Oh no! Ti sei perso?</MainSubTitle>
              </div>
            </div>
          </MacOsContainer>
        </div>
        <div className={styles.threerRectangles}>
          <Rectangle
            offset={{ x: -60, y: 0 }}
            color={"var(--wdd-purple)"}
            length={150}
            thickness={13}
          ></Rectangle>
          <Rectangle
            offset={{ x: -40, y: 20 }}
            color={"var(--wdd-purple)"}
            length={150}
            thickness={13}
          ></Rectangle>
          <Rectangle
            offset={{ x: -80, y: 40 }}
            color={"var(--wdd-purple)"}
            length={150}
            thickness={13}
          ></Rectangle>
        </div>
      </div>

      <div className={styles.outerCircle}>
        <Circle
          radius={63}
          borderColor={windowSize.width < 911 ? "transparent" : "white"}
          borderThickness={5}
        >
          <div className={styles.innerCircle}>
            <Circle
              radius={54}
              borderColor={"var(--wdd-yellow)"}
              borderThickness={3}
            >
              <Link to="/" className={styles.button}>
                <Button variant={"light-purple"} bold={true}>
                  Torna indietro
                </Button>
              </Link>
            </Circle>
          </div>
        </Circle>
      </div>
    </div>
  )
}

export default NotFound
